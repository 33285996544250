import * as React from "react";
function NameLogo() {
  return (
    <a id="logo-anchor" href="/">
      <svg
        id="logo"
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="2048.000000pt"
        height="2048.000000pt"
        viewBox="0 0 2048.000000 2048.000000"
        preserveAspectRatio="xMidYMid"
      >
        <g
          transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            id="name-logo"
            d="M3260 17424 c-6 -321 33 -13763 40 -13780 4 -11 520 -11 3294 1 1788
8 3377 14 3531 15 504 0 917 39 1380 131 1269 251 2423 843 3370 1727 l140
131 1010 -1010 c737 -736 1016 -1009 1032 -1009 l23 0 -6 1018 c-4 960 -16
4635 -31 10230 -7 2634 -4 2512 -49 2512 -10 0 -124 -220 -328 -632 -825
-1667 -1015 -2048 -1020 -2048 -2 0 -35 39 -73 88 -264 339 -650 734 -1008
1032 -955 795 -2139 1324 -3375 1509 -418 62 -583 70 -1650 75 -2259 12 -6280
18 -6280 10z m6232 -60 l637 -7 -1122 -2243 c-617 -1234 -1126 -2244 -1131
-2244 -5 0 -490 483 -1078 1073 -589 589 -1602 1605 -2251 2255 l-1182 1184
2745 -6 c1510 -3 3032 -9 3382 -12z m1093 -14 c529 -35 997 -117 1495 -260
1371 -396 2617 -1243 3494 -2376 l47 -62 -686 -1383 c-378 -761 -831 -1675
-1007 -2031 -177 -357 -324 -648 -328 -648 -4 0 -240 465 -525 1033 -676 1348
-1547 3083 -2284 4549 -325 647 -591 1179 -591 1182 0 9 221 7 385 -4z m-6463
-802 c434 -436 1452 -1456 2261 -2265 l1472 -1473 -565 -1130 c-310 -621 -568
-1126 -571 -1122 -14 15 -3400 6782 -3394 6782 4 0 362 -357 797 -792z m12881
-2218 c3 -1642 3 -2971 0 -2955 -60 402 -100 613 -159 845 -171 664 -422 1268
-772 1855 -75 126 -260 406 -339 512 -29 40 -53 77 -53 82 0 4 221 453 491
997 270 544 565 1139 655 1323 91 184 166 332 168 330 2 -2 6 -1347 9 -2989z
m-5833 985 c546 -1089 1305 -2602 1687 -3363 382 -761 693 -1385 690 -1388 -3
-3 -758 -10 -1677 -16 l-1673 -10 -1141 1141 -1141 1141 1123 2246 c618 1236
1127 2243 1131 2238 5 -5 455 -900 1001 -1989z m-6167 -1429 l1697 -3388
-1677 -3354 c-923 -1844 -1679 -3351 -1682 -3349 -2 3 -12 2537 -22 5633 -18
5577 -22 7859 -16 7851 2 -2 767 -1529 1700 -3393z m10861 419 c587 -886 960
-1895 1085 -2935 40 -333 46 -439 46 -840 -1 -424 -12 -596 -61 -945 -149
-1066 -562 -2107 -1183 -2982 -65 -93 -89 -119 -97 -110 -18 19 -2024 4022
-2024 4039 0 14 202 423 1342 2718 220 443 459 924 530 1070 72 146 135 271
140 278 13 15 56 -41 222 -293z m-5748 -3763 c-6 -5 -3337 -25 -3342 -19 -3 3
248 510 556 1128 l562 1122 1113 -1113 c613 -613 1113 -1116 1111 -1118z
m3444 -38 c0 -8 -1090 -2207 -1099 -2216 -3 -4 -504 490 -1111 1097 l-1105
1105 405 1 c344 2 1623 10 2633 17 152 1 277 -1 277 -4z m-2256 -1143 l1130
-1130 -221 -448 c-223 -451 -655 -1322 -1541 -3106 -496 -998 -496 -998 -529
-954 -6 6 -769 1527 -1697 3380 l-1687 3367 423 1 c233 1 860 5 1393 8 534 4
1112 8 1285 9 l315 2 1129 -1129z m3036 -366 c403 -803 856 -1704 1006 -2003
151 -299 274 -549 274 -557 0 -32 -309 -403 -497 -595 l-112 -115 -1261 1261
-1261 1260 536 1080 c296 593 544 1094 552 1113 8 19 18 30 23 25 4 -5 338
-666 740 -1469z m-5922 -1926 l1681 -3357 -1112 -6 c-1856 -11 -5631 -25
-5635 -22 -2 2 756 1523 1685 3380 928 1856 1690 3372 1694 3368 3 -4 763
-1517 1687 -3363z m8614 -1926 c2 -1034 0 -1390 -8 -1380 -6 6 -310 607 -675
1334 l-663 1322 42 58 c608 847 1001 1748 1197 2743 19 96 43 238 54 315 11
77 23 165 28 195 7 54 18 -1833 25 -4587z m-2122 481 c-81 -82 -332 -304 -465
-410 -1019 -818 -2255 -1326 -3560 -1464 -184 -20 -599 -45 -628 -39 -18 5
-83 -127 1117 2289 450 905 879 1770 954 1923 l136 278 1255 -1255 1256 -1256
-65 -66z m1400 -553 l648 -1294 -953 953 -953 953 140 151 c136 146 346 392
418 488 19 26 39 46 44 45 4 -2 300 -585 656 -1296z"
          />
        </g>
      </svg>
    </a>
  );
}
export default NameLogo;
